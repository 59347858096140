<template>
  <div class="cart-item-outer">
    <p class="primary--text small--text" v-if="title">{{ title }}</p>

    <div class="buttons">
      <!-- <pre>{{ sum }}</pre> -->
      <div class="button-outer">
        <v-select v-model="selected" :items="options" item-text="title" return-object></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Medium from "../../modules/app-module/medium";
export default {
  name: "print-cart-item-field",

  props: {
    value: [String, Object, Number, Array],
    title: String,
    disabled: Boolean,
    loading: Boolean,
  },

  data() {
    return {
      model: this.value,
      error: false,
      selected: null,
      options: [
        "Canson Infinity Baryta Photographique II (Warm Base/Satin)",
        "Canson Infinity Platine Fibre Rag (Pure White/Satin)",
        "Canson Infinity Rag Photographique (Warm Base Rag Paper)",
        "Canson BKF Rives Pure White (Cool Base/Rag Paper) 4",
      ],
    };
  },

  computed: {},

  methods: {
    async getMediums() {
      const compMediums = this.model?.data?.competition?.data?.printingMediums;
      const response = await this.$fluro.content.getMultiple("medium", compMediums).then((res) => res);
      this.options = response;
    },
  },

  async mounted() {
    await this.getMediums();
  },
  watch: {
    selected(newPaper) {
      console.log(newPaper);
      this.$emit("setPaper", newPaper);
    },
  },
};
</script>

<style lang="scss">
.cart-item-outer {
  width: 100%;

  .buttons {
    margin: -12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .button-outer {
      padding: 12px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .field {
        width: 60px;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-inner-spin-button,
      input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input {
        text-align: center;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
</style>
