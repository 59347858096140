<template>
    <v-dialog v-model="display" content-class="confirm" :fullscreen="$vuetify.breakpoint.xsOnly" width="600">
        <v-card class="awards-dialog no-selection">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="4" v-for="item in model" :key="item._id">
                        <v-card
                            class="background pa-6 elevation-0 rounded-lg d-flex align-center flex-column p-relative"
                            @click="download(item)"
                        >
                            <v-img class="rounded-lg" :src="$fluro.asset.imageUrl(item)">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular
                                            :width="1"
                                            :size="14"
                                            color="dark"
                                            indeterminate
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>

                            <div class="p-absolute bottom right pa-2">
                                <v-btn class="pa-2" color="dark" icon>
                                    <v-icon color="dark" small>mdi-download-outline</v-icon>
                                </v-btn>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
// Packages
import _ from "lodash";

export default {
    name: "awards-dialog",

    props: {
        title: {
            type: String,
            default() {
                return "Download Awards";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        selectable: {
            type: Array,
            default() {
                return [];
            },
        },
        selected: {
            type: Array,
            default() {
                return [];
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            message: "",
            errorMessage: "",
            model: [],
        };
    },

    methods: {
        init(model) {
            this.showError = false;
            this.loading = false;

            this.model = model;
        },
        open(model) {
            this.init(model);
            this.display = true;
        },
        download(item) {
            // window.open(`https://api.appbooks.com/download/${item}`, "_blank");

            var link = document.createElement("a");
            const token = this.$fluro.auth.getCurrentToken();
            if (link.download !== undefined) {
                link.setAttribute(
                    "href",
                    `https://api.appbooks.com/download/${item._id}?redirect=false&access_token=${token}`
                );
                link.setAttribute("download", `${item.title} award.png`);
                link.setAttribute("target", "_blank");
                link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        close() {
            this.display = false;
            this.model = null;
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.awards-dialog {
    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
