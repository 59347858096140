<template>
    <v-dialog v-model="display" persistent content-class="confirm" :fullscreen="$vuetify.breakpoint.xsOnly" width="600">
        <v-card class="entry-purchase-dialog no-selection dark--text">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-container fluid>
                <v-row>
                    <v-col cols="12" v-if="entry">
                        <div class="group-item pa-3 d-flex flex-column full-width">
                            <print-cart-item-field
                                v-model="entry"
                                title="Select a Paper Stock"
                                @setPaper="setPaper"
                            ></print-cart-item-field>

                            <hr class="my-6 full-width" />
                            <v-row>
                                <v-col cols="12">
                                    <div class="small--text">
                                        Please choose your preferred paper stock. If unsure, Canson Infinity Rag
                                        Photographique is recommended due to versatility, pictorial depth, and art
                                        reproduction qualities. Additional information on paper characteristics can be
                                        found here:
                                        <a
                                            href="https://www.canson-infinity.com/en/products/baryta-photographique-ii-matt"
                                            target="_blank"
                                            color="blue"
                                            class="blue--text"
                                            >https://www.canson-infinity.com/en/products/baryta-photographique-ii-matt</a
                                        >
                                        <br />
                                        <br />
                                        Images will be automatically formatted as either vertical (if a vertical or
                                        square image) or horizontal, with an appropriate white boarder for judging. Any
                                        non-conventional formatting requirements should be emailed to nlarkin@psc.edu.au
                                        directly, within 24 hours of entry submission.
                                        <br />
                                        <br />
                                        Fine art prints are produced using custom profiled Epson Printers, with top end
                                        colour management and archival Ultra chrome pigment inks. Prints will be
                                        packaged in A3 acid free sleeves with entry details attached and automatically
                                        delivered to the judging venue.
                                        <br />
                                        <br />
                                        Ensure you have paid the return print fee ($35 for unlimited prints) and have
                                        registered your postal address if you would like your prints returned to you.
                                        Prints which are not returned will be added to The Australian Photographic Prize
                                        historical archive collection.
                                    </div>
                                </v-col>
                            </v-row>

                            <div v-if="entry && entry.data && returnPaid">
                                <hr class="my-6 full-width" />
                                <v-row>
                                    <v-col cols="12" class="d-flex flex-column">
                                        <div class="small--text">
                                            The return print fee for this entry: <b>{{ entry.data.entryCode }}</b> has
                                            already been purchased.
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-else-if="entry && entry.data && returnPrice && !returnPaid">
                                <hr class="my-6 full-width" />
                                <v-row>
                                    <v-col cols="12" class="d-flex flex-column">
                                        <div class="small--text">
                                            The return print fee for this entry: <b>{{ entry.data.entryCode }}</b> has
                                            not been paid. An additional cost (<b>${{ returnPrice }}</b
                                            >) is required to have your print returned.
                                        </div>
                                        <v-checkbox
                                            class="small--text"
                                            v-model="printFeeCheckbox"
                                            :label="`I want my professional print returned.`"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </div>

                            <div class="p-relative group-item pa-3 mt-6">
                                <div class="full-width">
                                    <v-card
                                        class="pa-2 mb-3 background elevation-0 payment d-flex align-center justify-space-between"
                                        style="height: 56px"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon class="mr-2" color="primary">mdi-credit-card-outline</v-icon>
                                            <h4>Amount Due:</h4>
                                        </div>

                                        <h4>
                                            <span>{{ price | currency }}</span>
                                            <span style="font-weight: 300">({{ $app.currency || "AUD" }})</span>
                                        </h4>
                                    </v-card>

                                    <stripe-element v-if="stripe" ref="stripeElement" :stripe="stripe"></stripe-element>
                                </div>

                                <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loadingStripe">
                                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                                </v-overlay>
                            </div>
                            <!-- </template>
                            </v-radio-group> -->
                        </div>
                    </v-col>
                </v-row>

                <!-- <v-row>
                    <v-col cols="12">
                        <v-checkbox class="ma-0 pa-0">
                            <template v-slot:label>
                                <span class="small--text"
                                    >I have read and accept the
                                    <span class="primary--text cursor-pointer" @click="goToTerms()"
                                        >Terms and Conditions</span
                                    >
                                    for entering the {{ orgData.title }}</span
                                >
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row> -->
            </v-container>

            <v-card-actions class="actions light pa-3 sticky bottom">
                <v-btn :color="cancelColor" uppercase @click="cancel"
                    ><span class="dark--text">{{ cancelLabel }}</span></v-btn
                >
                <v-btn :disabled="!isValid" :color="actionColor" uppercase @click="submit">{{ actionLabel }}</v-btn>
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
// Services
// Components
import AutocompleteField from "@/components/form-field/autocomplete-field.vue";
// import ButtonsField from '@/components/form-field/buttons-field.vue';
import PrintCartItemField from "@/components/form-field/print-cart-item-field.vue";
import StripeElement from "@/components/global/stripe-element.vue";

// Packages
import _ from "lodash";
import moment from "moment";

export default {
    name: "print-purchase-dialog",

    components: {
        AutocompleteField,
        // ButtonsField,
        StripeElement,
        PrintCartItemField,
    },

    props: {
        entry: {
            type: Object,
        },
        title: {
            type: String,
            default() {
                return "Purchase Print";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    data() {
        return {
            display: false,
            loading: false,
            loadingStripe: false,
            showError: false,
            stripe: null,
            message: "",
            errorMessage: "",
            realm: "",
            integration: "",
            selectedPaper: null,
            printFeeCheckbox: false,
        };
    },

    computed: {
        user() {
            return this.$app.user;
        },
        returnPrice() {
            let returnPrice = this.entry?.data?.competition?.data?.returnPrice;
            returnPrice = _.isNumber(returnPrice) ? (returnPrice / 100).toFixed(2) : undefined;
            return returnPrice;
        },
        orgData() {
            return this.$store.getters["org/getOrgData"];
        },

        dialogMessage() {
            if (this.showError) return this.errorMessage || "An error occured. Try again.";
            return this.message || "";
        },
        returnPaid() {
            return this.checkReturnFeePaid(this.entry);
        },
        //TO DO put in the database somehwere
        price() {
            console.log("RETURN PAUD", this.returnPaid);
            if (this.printFeeCheckbox && !this.returnPaid) {
                return this.entry?.data?.competition?.data?.returnPrice + this.selectedPaper?.data?.price;
            }
            return this.selectedPaper?.data?.price;
        },
        isValid() {
            return this.selectedPaper;
        },
    },

    methods: {
        checkReturnFeePaid(entry) {
            const returnFeePaid = entry?.data?.competition?.data?.returnFeePaid || [];
            const returnPaid = returnFeePaid.includes(entry.managedOwners[0]._id);
            return returnPaid;
        },
        getImage(image) {
            if (image) {
                return this.$fluro.asset.imageUrl(image._id);
            }
        },
        //emit from the cart
        setPaper(item) {
            console.log("NEW ITEM", item);
            this.selectedPaper = item;
        },
        reset() {
            this.stripe = null;
            this.loading = false;
            this.showError = false;
            this.errorMessage = "";
        },
        async open(message) {
            this.message = message;
            this.display = true;
            await this.init();
        },
        async init() {
            this.reset();
            await this.getStripe();
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            this.$emit("confirm", this.model);
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
        },
        async getStripe() {
            this.loadingStripe = true;

            try {
                console.log("GETTING STRIPE");
                const integration = await this.$fluro.api
                    .get(`/content/integration/${this.$app.liveIntegration}`)
                    .then(({ data }) => data);
                this.realm = this.$app.liveRealm;

                if (!integration) {
                    throw Error("Cannot find an integration");
                } else {
                    this.integration = integration;
                    // console.warn("INTEGRATION", this.integration);
                }

                let key = integration.publicDetails.livePublicKey;

                if (integration.publicDetails.sandbox) {
                    key = integration.publicDetails.testPublicKey;
                }

                this.stripe = window.Stripe(key);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loadingStripe = false;
        },

        async submit() {
            this.loading = true;

            try {
                let payload = {};

                let user = this.$app.user;

                if (!user) throw new Error("Could not find a user");

                let card = this.$refs.stripeElement.getCard();

                console.log("CARD", card);
                let incomplete = [];

                if (!card || !card.cardName) {
                    incomplete.push("cardName");
                }

                if (incomplete.length) throw new Error();

                let token = await this.stripe
                    .createToken(card.cardNumber, { name: card.cardName, email: user.email })
                    .then((res) => res.token);

                console.log("TOKEN", token);
                let logo = this.getImage(this.orgData.data.logoLight);
                let entryImage = this.getImage(this.entry.data.image);
                // let options = { year: "numeric", month: "long", day: "numeric" };
                // let endSubmitDate = new Date(this.myComp.data.submission.endDate).toLocaleString(undefined, options);
                // let startJudgeDate = new Date(this.myComp.data.judging.startDate).toLocaleString(
                //     undefined,
                //     options
                // );
                console.log(this.entry);
                if (token && token.id) {
                    payload = {
                        entryId: this.entry._id,
                        userToken: this.$fluro.auth.getCurrentToken(),
                        // endSubmitDate: endSubmitDate,
                        // startJudgeDate: startJudgeDate,
                        medium: this.selectedPaper._id,
                        stripeToken: token.id,
                        personaId: user.persona,
                        competition: this.entry.data.competition,
                        realm: this.$app.liveRealm,
                        integration: this.integration?._id,
                        orgData: this.orgData,
                        logo: logo,
                        entryImage: entryImage,
                        returnPrint: this.printFeeCheckbox,
                        addPrintFee: this.printFeeCheckbox,
                        returnFeePaid: this.entry?.data?.competition?.data?.returnFeePaid || [],
                        name: this.user.name,
                        email: this.user.email,
                    };
                } else {
                    throw new Error("Could not get a Stripe token");
                }

                console.log("reaction payload", payload);
                let response = await this.$fluro.api
                    .post("/reaction/spark/646eee1e47801e220576426d", payload)
                    .then(({ data }) => data)
                    .catch((error) => {
                        console.log("ERROR", error);
                        this.showError = true;
                        this.errorMessage = error;
                        this.loading = false;
                    });

                if (response?.message) {
                    console.log("ERROR", response.result);
                    this.showError = true;
                    this.errorMessage = response.result;
                    this.loading = false;
                } else if (response) {
                    console.log("RESPONSE", response);
                    this.$emit("confirm", response);
                }
            } catch (err) {
                console.log("ERROR", err);
                this.showError = true;
                this.errorMessage = err;
                this.loading = false;
            }

            // this.loading = false;
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.entry-purchase-dialog {
    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
