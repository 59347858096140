<template>
    <div class="my-submission full-height full-width">
        <div class="dialog-error error px-4" :class="{ active: error }">
            <div class="text d-flex align-center">
                <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                <b class="white--text">{{ errorMessage }}</b>
            </div>

            <v-btn x-small icon fab color="white" @click="error = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>

        <div class="overflow-hidden full-height full-width p-relative d-flex flex-column">
            <template v-if="loading">
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-container>
            </template>

            <template v-else>
                <div class="p-absolute top zindex-10 full-width">
                    <v-container
                        fluid
                        class="pa-6 page-header"
                        :class="{ scrolled: scrolled || $vuetify.breakpoint.xsOnly }"
                        v-if="entry && entry._id"
                    >
                        <v-row>
                            <v-col class="section-header d-flex align-center justify-space-between" cols="12">
                                <!--TITLE-->
                                <div class="d-flex align-center">
                                    <template v-if="$vuetify.breakpoint.smAndUp">
                                        <template v-if="!submitted">
                                            <h2
                                                class="mr-3"
                                                v-html="
                                                    entry.status == 'active' || entry.status == 'archived'
                                                        ? 'Edit Submission'
                                                        : 'New Submission'
                                                "
                                            ></h2>
                                        </template>

                                        <template v-else-if="submittedOn">
                                            <h2 class="mr-3">
                                                Submitted: <span class="primary--text">{{ submittedOn }}</span>
                                            </h2>
                                        </template>
                                        <template v-if="$status.competitionOpen(competition)">
                                            <div class="status-cell d-flex align-center">
                                                <span class="inline-tag text-uppercase active">Open</span>
                                            </div>
                                        </template>

                                        <template v-else-if="$status.competitionJudging(competition)">
                                            <div class="status-cell d-flex align-center">
                                                <span class="inline-tag text-uppercase draft">Judging</span>
                                            </div>
                                        </template>

                                        <template v-else-if="$status.competitionSubmissionClosed(competition)">
                                            <div class="status-cell d-flex align-center">
                                                <span class="inline-tag text-uppercase deleted"
                                                    >Submissions Closed</span
                                                >
                                            </div>
                                        </template>

                                        <template v-else-if="$status.competitionClosed(competition)">
                                            <div class="status-cell d-flex align-center">
                                                <span class="inline-tag text-uppercase archived"
                                                    >Competition Closed</span
                                                >
                                            </div>
                                        </template>
                                    </template>
                                </div>

                                <!--PRINT Button-->
                                <div class="d-flex align-center">
                                    <template v-if="isPrint">
                                        <v-btn
                                            class="ml-3"
                                            :class="{ grey: $vuetify.breakpoint.mdAndDown }"
                                            :icon="$vuetify.breakpoint.mdAndDown"
                                            v-if="!printPurchased"
                                            color="blue"
                                            small
                                            @click="$refs.purchasePrintDialog.open(entry)"
                                        >
                                            <template v-if="$vuetify.breakpoint.mdAndDown">
                                                <v-icon color="dark" small>mdi-printer</v-icon>
                                            </template>

                                            <template v-else>Buy Print</template>
                                        </v-btn>
                                        <p class="ma-0 pa-0" v-else>Print Purchased</p>
                                    </template>
                                </div>

                                <!--ACTION Buttons-->
                                <div class="action-buttons d-flex align-center">
                                    <template v-if="published">
                                        <div class="d-flex align-center no-wrap">
                                            <b
                                                >Final Score:
                                                <span
                                                    class="success py-1 px-2 rounded white--text mx-1"
                                                    v-html="score"
                                                ></span
                                            ></b>
                                        </div>

                                        <div
                                            class="ml-3 cursor-pointer d-flex align-center"
                                            v-if="awards.length"
                                            @click="openAwardsDialog()"
                                        >
                                            <div class="mx-1 rounded-lg" v-for="(item, index) in awards" :key="index">
                                                <v-img
                                                    class="rounded-lg"
                                                    :src="$fluro.asset.imageUrl(item)"
                                                    width="35"
                                                    height="35"
                                                >
                                                    <template v-slot:placeholder>
                                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                                            <v-progress-circular
                                                                :width="1"
                                                                :size="14"
                                                                color="dark"
                                                                indeterminate
                                                            ></v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>
                                            </div>
                                        </div>
                                    </template>

                                    <v-btn
                                        class="ml-3"
                                        :class="{ grey: $vuetify.breakpoint.mdAndDown }"
                                        :icon="$vuetify.breakpoint.mdAndDown"
                                        color="grey"
                                        small
                                        @click="close"
                                    >
                                        <template v-if="$vuetify.breakpoint.mdAndDown">
                                            <v-icon color="dark" small>mdi-close</v-icon>
                                        </template>

                                        <template v-else>Close</template>
                                    </v-btn>

                                    <template v-if="!submitted && $status.competitionOpen(competition)">
                                        <v-btn
                                            class="ml-3"
                                            :class="{ success: $vuetify.breakpoint.mdAndDown }"
                                            :icon="$vuetify.breakpoint.mdAndDown"
                                            color="success"
                                            small
                                            :loading="saving"
                                            :disabled="!canSave"
                                            @click="save"
                                        >
                                            <template v-if="$vuetify.breakpoint.mdAndDown">
                                                <v-icon color="white" small>mdi-check</v-icon>
                                            </template>

                                            <template v-else>Save</template>
                                        </v-btn>

                                        <v-btn
                                            class="ml-3"
                                            :class="{ primary: $vuetify.breakpoint.mdAndDown }"
                                            :icon="$vuetify.breakpoint.mdAndDown"
                                            v-if="entry.status == 'active'"
                                            color="primary"
                                            small
                                            :loading="submitting"
                                            :disabled="!canSubmit"
                                            @click="
                                                $refs.submitDialog.open(
                                                    null,
                                                    `Are you sure you want to submit <b>${entry.title}</b>? This will finalise your submission and you will not be able to update it further.`
                                                )
                                            "
                                        >
                                            <template v-if="$vuetify.breakpoint.mdAndDown">
                                                <v-icon color="white" small>mdi-tray-arrow-up</v-icon>
                                            </template>

                                            <template v-else>Submit</template>
                                        </v-btn>
                                    </template>

                                    <template
                                        v-if="
                                            (isPrint &&
                                                submitted &&
                                                !$status.competitionJudging(competition) &&
                                                !$status.competitionClosed(competition)) ||
                                            (isPrint &&
                                                $status.hasEnoughInfo(entry) &&
                                                ($status.competitionJudging(competition) ||
                                                    $status.competitionSubmissionClosed(competition)))
                                        "
                                    >
                                        <v-btn
                                            class="light--text ml-3"
                                            :class="{ dark: $vuetify.breakpoint.mdAndDown }"
                                            :icon="$vuetify.breakpoint.mdAndDown"
                                            color="dark"
                                            small
                                            @click="print()"
                                        >
                                            <v-icon color="light" small>mdi-printer-outline</v-icon>
                                            <span class="ml-2" v-if="!$vuetify.breakpoint.mdAndDown"
                                                >Print Details</span
                                            ></v-btn
                                        >
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <div class="overflow-y-scroll full-height full-width">
                    <div class="spacer"></div>

                    <submission
                        v-if="model && model._id"
                        v-model="model"
                        :read-only="submitted || !$status.competitionOpen(competition)"
                        :entry="entry"
                        :entries="entries"
                        :categories="categories"
                        @updateEntry="save"
                    ></submission>
                </div>
            </template>
        </div>
        <print-purchase-dialog
            ref="purchasePrintDialog"
            @confirm="confirmPrintPayment"
            :entry="entry"
        ></print-purchase-dialog>
        <alert-dialog ref="submitDialog" @confirm="submit"></alert-dialog>
        <print-dialog ref="printDialog" @confirm="confirmPrint"></print-dialog>
        <awards-dialog ref="awardsDialog"></awards-dialog>
    </div>
</template>

<script>
// Services
import Entry from "@/modules/app-module/entry";
import Category from "@/modules/app-module/category";

// Components
import PrintPurchaseDialog from "@/components/dialogs/print-purchase-dialog.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";
import PrintDialog from "@/components/dialogs/print-dialog.vue";
import AwardsDialog from "@/components/dialogs/awards-dialog.vue";
import Submission from "@/components/layout/submission.vue";

// Packages
import _ from "lodash";
import moment from "moment";

export default {
    name: "my-submission",

    meta: {
        title: "Submission",
    },

    components: {
        PrintDialog,
        AlertDialog,
        AwardsDialog,
        Submission,
        PrintPurchaseDialog,
    },

    data() {
        return {
            error: false,
            errorMessage: "",
            entries: [],
            entry: {},
            categories: [],
            scrolled: false,
            model: {
                data: {
                    image: null,
                    category: "",
                    questions: ["test", 312],
                    cameraUsed: "",
                    cameraOther: "",
                    printerUsed: "",
                    printerOther: "",
                    paperUsed: "",
                    paperOther: "",
                    additionalImages: [],
                    body: "",
                },
                status: "active",
            },
            loading: false,
            saving: false,
            submitting: false,
        };
    },

    computed: {
        printPurchased() {
            return this.entry?.data?.printingPaid;
        },
        competition() {
            return this.entry?.data?.competition;
        },
        published() {
            return this.entry?.data?.published || false;
        },
        score() {
            return this.entry?.data?.score || 0;
        },
        awards() {
            let awards = this.entry?.data.awards || [];

            return awards;
        },
        canSave() {
            let model = this.model;
            let valid = false;

            if (!model.data.category || !model.data.category.length) return valid;

            valid = true;

            return valid;
        },
        canSubmit() {
            let model = this.model;
            //Valid is false until the end
            let valid = false;

            if (!model.title.length) return valid;

            if (!model.data.image && this.competition.data.media == "image") return valid;
            if (!model.data.videoSourceShort && !model.data.videoSourceFull && this.competition.data.media == "video")
                return valid;
            //Checking for required questions
            let isValid = model.data.questions.some((element, i) => {
                return element == "" && model?.data?.competition?.data?.questions[i]?.required;
            });
            if (isValid) {
                return valid;
            }
            //Checking for question length
            isValid = model.data.questions.some((element, i) => {
                return element?.length > model?.data?.competition?.data?.questions[i]?.limit;
            });
            if (isValid) {
                return valid;
            }

            if (!model.data.category || !model.data.category.length) return valid;

            if (
                (!model.data.cameraUsed && model.data.competition.data.cameraUsed) ||
                (!model.data.cameraUsed.length && model.data.competition.data.cameraUsed)
            )
                return valid;
            if (model.data.cameraUsed == "Other") {
                if (!model.data.cameraOther || !model.data.cameraOther.length) return valid;
            }

            if (this.isPrint) {
                // if (!model.data.printerUsed || !model.data.printerUsed.length) return valid;
                // if (model.data.printerUsed == "Other") {
                //   if (!model.data.printerOther || !model.data.printerOther.length) return valid;
                // }
                // if (!model.data.paperUsed || !model.data.paperUsed.length) return valid;
                // if (model.data.paperUsed == "Other") {
                //   if (!model.data.paperOther || !model.data.paperOther.length) return valid;
                // }
            }

            valid = true;

            return valid;
        },
        isPrint() {
            let model = this.model;
            let competition = model?.data?.competition;
            return competition?.data?.type == "print";
        },
        submitted() {
            let entry = this.entry;
            return entry && entry.data && entry.data.submitted;
        },
        submittedOn() {
            let entry = this.entry;
            return (
                entry && entry.data && entry.data.submittedOn && moment(entry.data.submittedOn).format("Do MMMM, YYYY")
            );
        },
    },

    methods: {
        async init() {
            this.loading = true;

            await this.$fluro.resetCache();

            let user = this.$app.user;
            let orgData = this.$store.getters["org/getOrgData"];
            if (user) {
                //Changed to query for performance
                const entryQuery = {
                    definition: "entry",
                    realms: {
                        $in: this.$app.liveRealm,
                    },
                    managedAuthor: user.persona,
                    _id: this.$route.params.id,
                };
                let entries = await Entry.query(entryQuery, { params: { appendPosts: "feedback" } }).then(
                    ({ data }) => data
                );
                this.entry = entries[0]; //Should only get one
                //////////////////////////////////////////////////
                console.log("ENTRY", this.entry);

                let categories = this.entry?.data?.competition?.data?.categories || [];

                if (categories?.length) {
                    this.categories = await Category.multiple({ params: { ids: categories } }).then(({ data }) => data);
                    console.log("CATEGORIES", this.categories);
                }

                if (this.entry.status == "active" || this.entry.status == "archived") {
                    this.model = _.cloneDeep(this.entry);

                    if (this.model?.data?.category) this.model.data.category = this.model.data.category._id;
                } else {
                    this.model = {
                        _id: this.entry._id,
                        title: "",
                        data: {
                            competition: this.entry.data.competition,
                            image: null,
                            category: "",
                            questions: [],
                            cameraUsed: "",
                            cameraOther: "",
                            printerUsed: "",
                            printerOther: "",
                            paperUsed: "",
                            paperOther: "",
                            additionalImages: [],
                            body: "",
                        },
                        status: "active",
                    };
                }

                // Have to filter again for the category check
                this.entries = entries.filter(
                    (entry) => entry?.data?.competition?._id == this?.model?.data?.competition?._id
                );
            }

            this.loading = false;
        },
        async confirmPrintPayment() {
            await this.$fluro.resetCache();
            //Do confirming stuff
            this.$refs.purchasePrintDialog.close();
        },
        close() {
            this.$router.push({ name: "my-submissions" });
        },
        getTitle() {
            // trying to get something like commercial_matt-blackburn_image-1.jpeg

            let user = this.$app.user;
            let model = this.model;
            let title = "";

            if (model?.data?.competition?._id) {
                let date = model?.data?.competition?.data?.submission?.startDate;

                if (date) {
                    title += `${new Date(date).getFullYear()}_`;
                }

                title += `${this.hyphenate(model.data.competition.title)}`;
            }

            if (model?.data?.category) {
                let category = this.categories.find((cat) => cat._id == model.data.category);
                title += `_${this.hyphenate(category.title)}`;
            }

            let owner = this.entry.managedOwners.find((owner) => owner._id == user.persona);
            if (owner?._id) {
                title += `_${this.hyphenate(owner.firstName)}-`;
                title += `${this.hyphenate(owner.lastName)}`;
            }

            if (this.entry?.data?.entryNumber)
                title += `_image-${this.entry?.data?.entryNumber}.${
                    model?.data?.image?.extension ? model.data.image.extension : "jpeg"
                }`;

            return title;
        },
        hyphenate(text) {
            let first = text.replace(/[,._.\- ]/g, "-").toLowerCase();
            let second = first.replace(/---/g, "-");

            return second;
        },
        async save() {
            this.saving = true;

            try {
                let entry = this.entry;
                let model = this.model;

                console.warn("SAVING ENTRY!!!", model);
                console.log("MODEL", model);

                // if (model.data && model.data.additionalImages && model.data.additionalImages.length) {
                //     model.data.additionalImages = model.data.additionalImages.map(image => {
                //         if (image && image._id) return image._id;
                //         return image;
                //     });
                // }

                if (!model?.data?.cameraUsed) model.data.cameraUsed = ""; // Coming back as undefined when unseleceted
                if (!model?.data?.paperUsed) model.data.paperUsed = ""; // Coming back as undefined when unseleceted
                if (!model?.data?.printerUsed) model.data.printerUsed = ""; // Coming back as undefined when unseleceted

                model.title = this.getTitle();

                let updated = await Entry.update(entry._id, model).then(({ data }) => data);

                console.log("UPDATED", updated);

                if (entry.status == "draft") {
                    await this.init();
                }
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.saving = false;
        },

        async submit() {
            this.submitting = true;

            let user = this.$app.user;

            try {
                if (user) {
                    //////////////////////////////////////////////////

                    // Save it first

                    let entry = this.entry;
                    let model = this.model;

                    console.log("MODEL", model);

                    let updated = await Entry.update(entry._id, model).then(({ data }) => data);

                    console.log("UPDATED", updated);

                    let contact = await this.$fluro.api
                        .post("/content/_query", { _type: "contact", "data.personaId": user.persona })
                        .then(({ data }) => data);
                    contact = contact[0];

                    if (!contact) throw Error("No valid contact");

                    //////////////////////////////////////////////////

                    // Then submit it
                    const orgData = this.$store.getters["org/getOrgData"];
                    let payload = {
                        contact,
                        orgData: orgData,
                        userToken: this.$fluro.auth.getCurrentToken(),
                        entries: [model._id],
                        endDate: new Date(this.competition.data.submission.endDate).toDateString(),
                    };

                    let response = await this.$fluro.api
                        .post("/reaction/spark/62a95d1620126e18f0567f22", payload)
                        .then(({ data }) => data);
                    console.log("RESPONSE", response);

                    if (response.result) {
                        console.log("ERROR", response.result);
                        this.error = true;
                        this.errorMessage = response.result;
                    } else {
                        await this.init();

                        this.$refs.submitDialog.close();

                        if (this.isPrint) {
                            this.$refs.printDialog.open(entry, "Would you like to print your submission now?");
                        }
                    }
                }
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.submitting = false;
        },
        confirmPrint() {
            this.$refs.printDialog.close();
            this.print();
        },
        print() {
            window.print();
        },
        openAwardsDialog() {
            this.$refs.awardsDialog.open(this.awards);
        },
        handleScroll(e) {
            let scrollTop = e.target.scrollTop;

            if (scrollTop >= 30) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
    },

    async mounted() {
        await this.init();

        this.$nextTick(() => {
            const scrollContainer = document.querySelector(".overflow-y-scroll");
            scrollContainer.addEventListener("scroll", this.handleScroll);
        });
    },

    destroyed() {
        const scrollContainer = document.querySelector(".overflow-y-scroll");
        if (scrollContainer) scrollContainer.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style lang="scss"></style>
